<template>
  <a-row>
    <a-col span="24">
      <a-collapse v-model:activeKey="activeKey">
        <template #expandIcon="{ isActive }">
          <RightOutlined :rotate="isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          showArrow
          key="1"
          header="Foto de Perfil"
          :class="$style.customStyle"
        >
          <a-col span="24">
            <div :class="[$style.space, $style.addMore, $style.outSpace]">
              <a-row>
                <a-col :span="isDesktop ? 8 : 24" :class="[$style.space, $style.photo]">
                  <table>
                    <thead>
                      <tr>
                        <th colspan="2">Regras</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">
                          <ul style="text-align: left">
                            <li>
                              A foto deve ter tamanho padrão para passaportes (passport
                              size), 2 x 2 polegadas (inches).
                            </li>
                          </ul>

                          <center><div :class="$style.example"></div></center>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ul style="text-align: left">
                            <li>Deve ser tirada de frente, com fundo branco.</li>
                            <li>
                              O rosto e os ombros devem estar completamente enquadrados
                              pela câmera e o requerente deve olhar diretamente a câmera.
                            </li>
                            <li>
                              Não pode haver reflexos, penumbras ou sombras em nenhuma
                              parte da fotografia.
                            </li>
                            <li>O requerente deve apresentar fisionomia neutra.</li>
                            <li>Os olhos devem estar abertos e visíveis.</li>
                            <li>
                              As lentes de óculos não podem refletir a luz ambiente ou da
                              câmera. De maneira alguma podem ser utilizados óculos
                              escuros ou óculos de armações grossas ou muito chamativas.
                            </li>
                            <li>
                              Não serão permitidos quaisquer itens de chapelaria, exceto
                              os utilizados por motivos religiosos, que, ainda assim, não
                              podem impedir a visualização perfeita do rosto do
                              requerente.
                            </li>
                            <li>
                              Crianças não podem aparecer com laços, chupetas, brinquedos
                              ou mãos de pessoas segurando-as
                            </li>
                            <li>As mulheres devem estár com os cabelos atrás da orelha.</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </a-col>
              </a-row>
            </div>
            <div :class="[$style.space, $style.addMore, $style.outSpace]">
              <a-row>
                <a-col
                  :span="isDesktop ? 8 : 24"
                  :class="
                    imageUrl
                      ? [$style.space, $style.photo, $style.minHeight]
                      : [$style.space, $style.photo]
                  "
                >
                  <p :class="$style.alert">
                    Não são permitidas selfies ou fotos com óculos, favor ler as regras
                    acima!
                  </p>
                  <img v-if="imageUrl" :src="imageUrl" alt="photo" width="300" />
                </a-col>
              </a-row>
            </div>
            <div :class="[$style.space, $style.outSpace]">
              <a-row>
                <a-col :span="isDesktop ? 8 : 24">
                  <a-upload-dragger
                    :fileList="fileList"
                    :customRequest="handleUpload"
                    :beforeUpload="beforeUpload"
                    :showUploadList="false"
                    name="photo"
                    :multiple="false"
                    @change="handleOnChange"
                  >
                    <p class="ant-upload-drag-icon">
                      <inbox-outlined></inbox-outlined>
                    </p>
                    <p class="ant-upload-text">
                      Clique ou arraste o arquivo para esta área para fazer upload
                    </p>
                    <p class="ant-upload-hint">Apenas permitido JPG ou PNG.</p>
                  </a-upload-dragger>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-collapse-panel>
      </a-collapse>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent } from "vue";
import { message } from "ant-design-vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  data() {
    return {
      activeKey: ["1"],
      loading: false,
      imageUrl: "",
      fileList: [],
    };
  },
  components: { RightOutlined },
  computed: {
    ...mapGetters("application", ["readOnlyForm"]),
    ...mapState("application", ["vForm", "isLoadingPhoto", "application"]),
  },
  methods: {
    ...mapActions("application", ["uploadPhoto"]),
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    handleOnChange({ fileList }) {
      this.fileList = fileList;
    },
    async handleUpload(options) {
      const { onSuccess, onError, file } = options;

      this.getBase64(file, async (base64Url) => {
        const result = await this.uploadPhoto({ photo: base64Url });
        if (result.status) {
          this.fileList = [];

          onSuccess(file);
          this.imageUrl = base64Url;
          this.loading = false;
        } else {
          const error = new Error("Some error");
          onError({ event: error });
        }
      });
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

      if (!isJpgOrPng) {
        message.error("You can only upload JPG file!");
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }

      return isJpgOrPng && isLt2M;
    },
  },
  mounted() {
    if (this.application.photo) {
      this.imageUrl = this.application.photo;
    }
  },
  watch: {},
});
</script>

<style module src="./style.scss" lang="scss"></style>
